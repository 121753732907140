import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import api from '../services/api';

interface AuthContextType {
  user: any | null;
  login: () => void;
  logout: () => void;
  isAuthenticated: boolean;
  isWhitelisted: boolean;
  token: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isWhitelisted, setIsWhitelisted] = useState(false);

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    setIsWhitelisted(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo');
  }, []);

  // Handle test user authentication and local storage sync
  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = localStorage.getItem('accessToken');
      const storedUser = localStorage.getItem('userInfo');
      
      if (storedToken && storedUser) {
        try {
          const userInfo = JSON.parse(storedUser);
          setToken(storedToken);
          setUser(userInfo);
          setIsWhitelisted(process.env.REACT_APP_WHITELISTED_EMAILS?.split(',').includes(userInfo.email) || false);
        } catch (error) {
          console.error('Failed to parse stored user info:', error);
          logout();
        }
      }
    };

    // Initial check
    handleStorageChange();

    // Listen for storage events (used by e2e tests)
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [logout]);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse: TokenResponse) => {
      console.log('login success callback');
      try {
        const { access_token } = tokenResponse;
        
        // First set the token to enable API calls
        setToken(access_token);
        
        console.log('Fetching user info');
        // Fetch user info
        const userInfo = await api.getUserInfo(access_token);
        
        // Update state in a specific order
        setUser(userInfo);
        setIsWhitelisted(process.env.REACT_APP_WHITELISTED_EMAILS?.split(',').includes(userInfo.email) || false);
        
        // Store in localStorage after successful API call
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        console.log('Dispatching storage event');
        // Dispatch storage event to ensure other tabs are updated
        window.dispatchEvent(new Event('storage'));
        
      } catch (error) {
        console.error('Authentication error:', error);
        logout();
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      logout();
    },
    flow: 'implicit',
    scope: 'email profile',
    prompt: 'consent'
  });

  const isAuthenticated = useMemo(() => !!user && !!token, [user, token]);

  const value = {
    user,
    login,
    logout,
    isAuthenticated,
    isWhitelisted,
    token
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
