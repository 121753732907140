import axios from 'axios';
import { Image } from '../types';

const API_URL = process.env.REACT_APP_API_URL;

export interface PaginatedResponse<T> {
  images: T[];
  totalCount: number;
  hasMore: boolean;
  nextCursor?: string;
}

const api = {
  uploadImage: async (formData: FormData, token: string, onUploadProgress: (progressEvent: any) => void) => {
    const response = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      onUploadProgress,
    });
    return response.data;
  },

  getImages: async (limit: number = 20, cursor?: string): Promise<PaginatedResponse<Image>> => {
    const response = await axios.get(`${API_URL}/images`, {
      params: { limit, cursor }
    });
    return response.data;
  },

  getImageById: async (id: string): Promise<Image> => {
    const response = await axios.get(`${API_URL}/images/${id}`);
    return response.data;
  },

  getUserInfo: async (accessToken: string) => {
    const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },
};

export default api;

// Add this function to your existing api.ts file
export const getGraphData = async (token: string) => {
  const response = await fetch(`${API_URL}/images/graph`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch graph data');
  }
  return response.json();
};
