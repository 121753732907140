import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { Button } from 'rsuite';

const Login: React.FC = () => {
  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    // Handle popup blocked scenario
    const handlePopupBlocked = () => {
      window.addEventListener('unload', () => {
        if (!isAuthenticated) {
          console.error('Popup may have been blocked');
          // Optionally show a user-friendly message
        }
      });
    };

    handlePopupBlocked();
    return () => window.removeEventListener('unload', handlePopupBlocked);
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <Navigate to="/browse" />;
  }

  return (
    <div className="login-page">
      <h1>Welcome to starz</h1>
      <p>Please sign in with your Google account to continue.</p>
      <Button 
        onClick={() => {
          try {
            login();
          } catch (error) {
            console.error('Login error:', error);
          }
        }} 
        appearance="primary" 
        data-testid="login-button"
      >
        Sign in with Google
      </Button>
    </div>
  );
};

export default Login;
